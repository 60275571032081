import './App.css';
import Home from "./components/Home/Home.tsx";
import './assets/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/style.css';
import './assets/css/yellow.css';

function App() {
  return (
      <>
          <Home/>
      </>
  );
}

export default App;
